export const leadIdGenerator = () => {
  // Generate 3 random numbers.
  let numbers = [];
  for (let i = 0; i < 3; i++) {
    numbers.push(Math.floor(Math.random() * 9));
  }

  // Generate 3 random uppercase letters.
  let letters = [];
  for (let i = 0; i < 3; i++) {
    letters.push(String.fromCharCode(Math.floor(Math.random() * 26) + 65));
  }

  // Return the 3 numbers followed by the 3 letters.
  return numbers.join("") + letters.join("");
};
