import { useLocation } from "react-router-dom";

const Hero = () => {
  let location = useLocation();

  const strapLine =
    location.pathname === "/enquiries"
      ? "Let's get qualified... Complete the enquiry form below"
      : "Fee free new build mortgage advice available 7 days a week";

  return (
    <>
      <div className="gradient-45 mt-16 animate-gradient !bg-[length:400%_400%] px-4 py-32 md:px-6 lg:px-8">
        <div className="mx-auto w-full max-w-7xl">
          <div className="flex flex-col items-center">
            <h2 className="mb-8 text-center text-3xl font-bold text-white">
              {strapLine}
            </h2>

            {/* <div className="mb-8">
              <ButtonLarge
                color="emerald"
                label="Get in touch"
                onClick={handelClick}
              />
            </div> */}
          </div>
        </div>
      </div>

      <div className="cur -mt-12 h-12 md:-mt-16 md:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className={`h-full w-full fill-current ${
            location.pathname === "/enquiries" ? "text-white" : "text-gray-100"
          }`}
        >
          <path
            d="M-216,1135.969s320,192,640,192,640-192,640-192Z"
            transform="translate(1064 1327.968) rotate(180)"
          />
        </svg>
      </div>
    </>
  );
};

export default Hero;
