import React from "react";

function Alert({ type, text, background }) {
  const getBackgroundClasses = () => {
    switch (type) {
      case "danger":
        return background === "white"
          ? "rounded-lg bg-white p-2"
          : "rounded-lg bg-red-100/25 dark:bg-red-600/25 p-2";
      case "warning":
        return background === "white"
          ? "rounded-lg bg-white p-2"
          : "rounded-lg bg-yellow-100/25 dark:bg-yellow-600/25 p-2";
      case "info":
        return background === "white"
          ? "rounded-lg bg-white p-2"
          : "rounded-lg bg-blue-100/25 dark:bg-blue-600/25 p-2";
      case "success":
        return background === "white"
          ? "rounded-lg bg-white p-2"
          : "rounded-lg bg-green-100/25 dark:bg-green-600/25 p-2";
    }
  };

  const getIcon = () => {
    switch (type) {
      case "danger":
        return (
          /* <!-- Heroicon name: solid/x-circle --> */
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-red-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        );
      case "warning":
        return (
          /* <!-- Heroicon name: solid/exclamation --> */
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-yellow-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        );
      case "info":
        return (
          /* <!-- Heroicon name: solid/information-circle --> */
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-blue-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
        );
      case "success":
        return (
          /*<!-- Heroicon name: solid/check-circle -->*/
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-green-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        );
    }
  };

  const getTextClasses = () => {
    switch (type) {
      case "danger":
        return background === "white"
          ? "text-sm font-medium text-red-700"
          : "text-sm font-medium text-red-700 dark:text-red-500";
      case "warning":
        return background === "white"
          ? "text-sm font-medium text-yellow-700"
          : "text-sm font-medium text-yellow-700 dark:text-yellow-500";
      case "info":
        return background === "white"
          ? "text-sm font-medium text-blue-700"
          : "text-sm font-medium text-blue-700 dark:text-blue-500";
      case "success":
        return background === "white"
          ? "text-sm font-medium text-green-700"
          : "text-sm font-medium text-green-700 dark:text-green-500";
    }
  };

  return (
    <div className={getBackgroundClasses()}>
      <div className="flex">
        <div className="flex-shrink-0">{getIcon()}</div>
        <div className="ml-3">
          <h3 className={getTextClasses()}>{text}</h3>
        </div>
      </div>
    </div>
  );
}

export default Alert;
