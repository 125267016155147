import { useState, useEffect } from "react";
import Joi from "joi";
import { has, isEmpty, merge, omit, pick } from "lodash";
import moment from "moment";
import InputField from "./form/InputField";
import Textarea from "./form/Textarea";

import AppointmentPicker from "./form/AppointmentPicker";

import { validateProperty, validateForm } from "../utils/validation";

import axios from "axios";
import { leadIdGenerator } from "../utils/leadIdGenerator";
import { ButtonDefault } from "../components/Buttons";

function FormDeveloperLeads({ setFormSubmitted }) {
  const [data, setData] = useState({
    appointmentDay: null,
    appointmentGroup: null,
    appointmentTime: null,
    appointmentCallSoon: 0,
    siteDeveloperName: "",
    siteDevelopmentName: "",
    sitePlotNumber: "",
    siteReferrersName: "",
    siteEmail: "",
    sitePhone: "",
    siteMobilePhone: "",
    siteNotes: "",
    clientFirstName: "",
    clientLastName: "",
    clientEmail: "",
    clientPhone: "",
    clientNotes: "",
  });
  const [formDataChange, setFormDataChange] = useState({});
  const [appointment, setAppointment] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!isEmpty(formDataChange))
      validateProperty(formDataChange, schemaObj, errors, setErrors);
  }, [formDataChange]);

  const schemaObj = {
    appointmentDay: Joi.any(),
    appointmentGroup: Joi.any(),
    appointmentTime: Joi.any(),
    appointmentCallSoon: Joi.any(),
    siteDeveloperName: Joi.any(),
    siteDevelopmentName: Joi.any(),
    sitePlotNumber: Joi.any(),
    siteReferrersName: Joi.any(),
    siteEmail: Joi.string().email({ tlds: false }).required().messages({
      "string.empty": "Please enter your email address",
      "string.email": "Please enter a valid email address",
    }),
    sitePhone: Joi.any(),
    siteMobilePhone: Joi.any(),
    siteNotes: Joi.any(),
    clientFirstName: Joi.string()
      .required()
      .messages({ "string.empty": "Please enter the client's first name" }),
    clientLastName: Joi.string()
      .required()
      .messages({ "string.empty": "Please enter the client's last name" }),
    clientEmail: Joi.string().email({ tlds: false }).required().messages({
      "string.empty": "Please enter the client's email address",
      "string.email": "Please enter a valid email address",
    }),
    clientPhone: Joi.any(),
    clientNotes: Joi.any(),
  };

  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value,
    };
    doAfterChange(input);
  };

  const doAfterChange = (input) => {
    setData({ ...data, [input.name]: input.value });
    setFormDataChange(input);
  };

  const handleSubmit = async () => {
    document.getElementById("contact").scrollIntoView();
    const leadId = leadIdGenerator();
    data.reference = leadId;
    //data.leadType = "Appointment";

    // DELETE after cascade update.
    data.appointmentDay =
      data.appointmentDay === null
        ? moment().format("YYYY-MM-DD")
        : data.appointmentDay;

    data.appointment = appointment;
    data.clientAppointmentText = appointment.text; // DELETE after cascade update.

    const emailToAdmin =
      process.env.NODE_ENV === "production"
        ? [{ email: "threshold.mortgages@gmail.com", name: "New Enquiry" }]
        : [{ email: "rich.ray@hotmail.co.uk", name: "Richard" }];

    const emailSubjectAdmin = `Contact Form App / Developer Lead / ${data.clientFirstName} ${data.clientLastName}`;

    const emailToSite = [
      {
        email: data.siteEmail,
      },
    ];
    const emailSubjectSite = "Thank you for your referral";

    const emailToClient = [
      {
        email: data.clientEmail,
        name: `${data.clientFirstName} ${data.clientLastName}`,
      },
    ];
    const emailSubjectClient = "We will contact you soon";

    let emailParams;
    emailParams = pick(data, [
      "reference",
      "siteDeveloperName",
      "siteDevelopmentName",
      "sitePlotNumber",
      "siteReferrersName",
      "siteEmail",
      "sitePhone",
      "siteMobilePhone",
      "siteNotes",
      "clientFirstName",
      "clientLastName",
      "clientEmail",
      "clientPhone",
      "clientNotes",
    ]);
    emailParams.leadId = leadId;

    emailParams.clientTimeToCall = appointment.text;

    if (data.appointmentDay !== null && data.appointmentTime !== null) {
      emailParams.dayToCall = appointment.day;
      emailParams.timeToCall = appointment.time;
      emailParams.dateToCall = moment(appointment.date).format("Do MMMM, YYYY");
    }

    const formatPhone = (phoneNumber) => {
      const formattedPhone = phoneNumber.substring(1).replace(/\s/g, "");
      const prefixedPhone = `+44${formattedPhone}`;
      return prefixedPhone;
    };

    const getSmsMsgSite = () => {
      const appointmentMessage =
        data.appointmentDay !== null && data.appointmentTime !== null
          ? `We have booked an appointment to speak with the client for:\n\n${appointment.text}\n\n`
          : "We will make contact with the client as soon as possible.\n\n";
      return `Thank you for your referral.\n\n${appointmentMessage}We'll also provide an update as soon as we have spoken to the client.\n\nFor all enquiries call 03300 249 115.`;
    };

    const getSmsMsgClient = () => {
      const appointmentMessage =
        data.appointmentDay !== null && data.appointmentTime !== null
          ? `We have booked you an appointment for:\n\n${appointment.text}\n\nAn adviser will call you by phone to discuss your requirements.\n\n`
          : "An adviser will call you by phone as soon as possible to discuss your requirements.\n\n";

      return `Thank you for your interest in Threshold Mortgages.\n\n${appointmentMessage}For all enquiries call 03300 249 115.`;
    };

    const filteredData = omit(data, [
      "appointmentGroup",
      "appointmentTime",
      "appointmentCallSoon",
    ]);

    const [
      emailToClientReport,
      emailToAdminReport,
      emailToSiteReport,
      smsToClientReport,
      smaToSiteReport,
      sendToDatabaseReport,
    ] = await Promise.allSettled([
      axios.post(process.env.REACT_APP_API_URL + "/api/send-email", {
        to: emailToClient,
        subject: emailSubjectClient,
        templateId: 212,
        params: emailParams,
      }),
      axios.post(process.env.REACT_APP_API_URL + "/api/send-email", {
        to: emailToAdmin,
        subject: emailSubjectAdmin,
        templateId: 177,
        params: emailParams,
      }),
      axios.post(process.env.REACT_APP_API_URL + "/api/send-email", {
        to: emailToSite,
        subject: emailSubjectSite,
        templateId: 211,
        params: emailParams,
      }),
      axios.post(process.env.REACT_APP_API_URL + "/api/send-sms", {
        recipient: formatPhone(data.clientPhone),
        content: getSmsMsgClient(),
      }),
      axios.post(process.env.REACT_APP_API_URL + "/api/send-sms", {
        recipient: formatPhone(data.siteMobilePhone),
        content: getSmsMsgSite(),
      }),
      axios.post(
        process.env.REACT_APP_API_URL + "/api/developer-leads",
        filteredData
      ),
      setFormSubmitted(true),
      // setFormData({ appointment: data }),
    ]);

    const getCallData = (report, target) => {
      return report.status === "fulfilled"
        ? has(report.value.data, target)
          ? report.value.data[target]
          : report.value.data.message
        : report.reason.message;
    };

    const getCallResponseStatusCode = (report) => {
      return has(report, "value.data.status")
        ? report.value.data.status
        : report.status === "fulfilled"
        ? 200
        : report.reason.response.status;
    };

    const report = {
      calls: {
        emailToClient: {
          status: emailToClientReport.status,
          data: getCallData(emailToClientReport, "messageId"),
          responseStatusCode: getCallResponseStatusCode(emailToClientReport),
        },
        emailToAdmin: {
          status: emailToAdminReport.status,
          data: getCallData(emailToAdminReport, "messageId"),
          responseStatusCode: getCallResponseStatusCode(emailToAdminReport),
        },
        emailToSite: {
          status: emailToSiteReport.status,
          data: getCallData(emailToSiteReport, "messageId"),
          responseStatusCode: getCallResponseStatusCode(emailToSiteReport),
        },
        smsToClient: {
          status: smsToClientReport.status,
          data: getCallData(smsToClientReport, "messageId"),
          responseStatusCode: getCallResponseStatusCode(smsToClientReport),
        },
        smsToSite: {
          status: smaToSiteReport.status,
          data: getCallData(smaToSiteReport, "messageId"),
          responseStatusCode: getCallResponseStatusCode(smaToSiteReport),
        },
        sendToDatabase: {
          status: sendToDatabaseReport.status,
          data: getCallData(sendToDatabaseReport, "id"),
          responseStatusCode: getCallResponseStatusCode(sendToDatabaseReport),
        },
      },
      leadId,
      data: emailParams,
    };

    axios
      .get(
        process.env.REACT_APP_API_URL + "/api/developer-leads/check/" + leadId
      )
      .then((response) => {
        console.log("success");
        merge(report.calls.sendToDatabase, {
          status: sendToDatabaseReport.status,
          data: "The record has been found",
          responseStatusCode: response.status,
        });
        const emailTo = [
          {
            email: "threshold.mortgages@gmail.com",
            name: "New Enquiry",
          },
        ];
        sendReport(report, emailTo);
      })
      .catch((error) => {
        console.log("error");
        merge(report.calls.sendToDatabase, {
          status: sendToDatabaseReport.status,
          data: error.message,
          responseStatusCode: error.response.status,
        });
        const emailTo = [
          {
            email: "newenquiry@thresholdmortgages.com",
            name: "New Enquiry",
          },
          {
            email: "daniella.stoner@thresholdmortgages.com",
            name: "Daniella Stoner ",
          },
          {
            email: "leah.newland@thresholdmortgages.com",
            name: "Leah Newland",
          },
          { email: "threshold.mortgages@gmail.com", name: "New Enquiry" },
        ];
        sendReport(report, emailTo);
      });

    const sendReport = (report, emailTo) => {
      axios.post(process.env.REACT_APP_API_URL + "/api/send-email", {
        to: emailTo,
        subject: `${leadId} Lead Report`,
        templateId: 220,
        params: report,
      });

      console.log("report", report);
    };

    /// deal with lead report and subscribing to mail list on backend service
  };

  return (
    <>
      <div className="mb-16">
        <div className="mb-4 flex justify-center sm:justify-start">
          <div className="w-full max-w-sm sm:max-w-full">
            <h3 className="mb-2 text-xl font-bold text-black">
              Book an appointment
            </h3>
            <p className="mb-0 text-lg text-black">
              Select a day and time slot for your dedicated adviser to call the
              client
            </p>
          </div>
        </div>

        <AppointmentPicker
          data={data}
          setData={setData}
          setAppointment={setAppointment}
        />
      </div>

      <div className="mb-16">
        <div className="mb-4 flex justify-center sm:justify-start">
          <div className="w-full max-w-sm sm:max-w-full">
            <h3 className="text-xl font-bold text-black">
              Developer's details
            </h3>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm">
              <InputField
                name="siteDeveloperName"
                label="Name of developer"
                type="text"
                help={null}
                value={data["siteDeveloperName"]}
                onChange={handleChange}
                error={errors["siteDeveloperName"]}
              />
            </div>
          </div>

          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm">
              <InputField
                name="siteDevelopmentName"
                label="Name of development"
                type="text"
                help={null}
                value={data["siteDevelopmentName"]}
                onChange={handleChange}
                error={errors["siteDevelopmentName"]}
              />
            </div>
          </div>

          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm">
              <InputField
                name="sitePlotNumber"
                label="Plot number (if applicable)"
                type="text"
                help={null}
                value={data["sitePlotNumber"]}
                onChange={handleChange}
                error={errors["sitePlotNumber"]}
              />
            </div>
          </div>

          <div className="hidden sm:block"></div>

          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm">
              <InputField
                name="siteReferrersName"
                label="Your name"
                type="text"
                help={null}
                value={data["siteReferrersName"]}
                onChange={handleChange}
                error={errors["siteReferrersName"]}
              />
            </div>
          </div>

          <div className="hidden sm:block"></div>

          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm">
              <InputField
                name="siteEmail"
                label="Email address"
                type="text"
                help={null}
                value={data["siteEmail"]}
                onChange={handleChange}
                error={errors["siteEmail"]}
              />
            </div>
          </div>

          <div className="hidden sm:block"></div>

          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm">
              <InputField
                name="sitePhone"
                label="Phone"
                type="text"
                help={null}
                value={data["sitePhone"]}
                onChange={handleChange}
                error={errors["sitePhone"]}
              />
            </div>
          </div>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm">
              <InputField
                name="siteMobilePhone"
                label="Mobile phone"
                type="text"
                help={null}
                value={data["siteMobilePhone"]}
                onChange={handleChange}
                error={errors["siteMobilePhone"]}
              />
            </div>
          </div>
          <div className="col-span-1 mb-0 flex justify-center sm:col-span-2">
            <div className="w-full max-w-sm sm:max-w-full">
              <Textarea
                name="siteNotes"
                label="Notes"
                help={null}
                value={data["siteNotes"]}
                onChange={handleChange}
                error={errors["siteNotes"]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-16">
        <div className="mb-4 flex justify-center sm:justify-start">
          <div className="w-full max-w-sm sm:max-w-full">
            <h3 className="text-xl font-bold text-black">Clients's details</h3>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm">
              <InputField
                name="clientFirstName"
                label="First name"
                type="text"
                help={null}
                value={data["clientFirstName"]}
                onChange={handleChange}
                error={errors["clientFirstName"]}
              />
            </div>
          </div>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm">
              <InputField
                name="clientLastName"
                label="Last name"
                type="text"
                help={null}
                value={data["clientLastName"]}
                onChange={handleChange}
                error={errors["clientLastName"]}
              />
            </div>
          </div>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm">
              <InputField
                name="clientEmail"
                label="Email address"
                type="text"
                help={null}
                value={data["clientEmail"]}
                onChange={handleChange}
                error={errors["clientEmail"]}
              />
            </div>
          </div>

          <div className="hidden sm:block"></div>

          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm">
              <InputField
                name="clientPhone"
                label="Phone"
                type="text"
                help={null}
                value={data["clientPhone"]}
                onChange={handleChange}
                error={errors["clientPhone"]}
              />
            </div>
          </div>

          <div className="hidden sm:block"></div>

          <div className="col-span-1 mb-0 flex justify-center sm:col-span-2">
            <div className="w-full max-w-sm sm:max-w-full">
              <Textarea
                name="clientNotes"
                label="Notes"
                help={null}
                value={data["clientNotes"]}
                onChange={handleChange}
                error={errors["clientNotes"]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mb-8 flex justify-center sm:justify-start">
        <div className="w-full max-w-sm sm:max-w-full">
          <p className="text-sm font-medium text-black dark:text-white">
            The internet is not a secure medium and the privacy of your data
            cannot be guaranteed.
          </p>
        </div>
      </div>

      <div className="mb-0 flex justify-center sm:justify-start">
        <div className="w-full max-w-sm sm:max-w-full">
          <ButtonDefault
            color="emerald"
            label="Submit"
            onClick={handleSubmit}
            disabled={validateForm(data, schemaObj)}
          />
        </div>
      </div>
    </>
  );
}

export default FormDeveloperLeads;
